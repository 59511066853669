import constants from '@lmig-latam/adil-api-common-lib/constants/constants';
import history from './history';
import { logcodes, logger } from './logger';
import { checkForTokens } from './customErrors';
import { isLocalhost } from '../config/environments';

export const navigate = async (newPath, state) => {
  const {
    location: { pathname },
    push,
  } = history;

  checkForTokens();

  logger.log(logcodes.CANAV010, { from: pathname, to: newPath });

  window.scrollTo(0, 0);

  // https://github.com/ReactTraining/history/blob/v4/docs/Navigation.md
  push({
    pathname: newPath,
    state,
    search: history.location.search,
    from: pathname,
  });
};

export const getCookie = cookieName => {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');

  // eslint-disable-next-line no-restricted-syntax
  for (const cookie of cookieArray) {
    let value = cookie;
    while (value.charAt(0) === ' ') {
      value = value.substring(1);
    }
    if (value.indexOf(name) === 0) {
      return value.substring(name.length, value.length);
    }
  }
  return '';
};

const getCountryCodeFromUrl = () => {
  const host = window.location.host.toLowerCase();

  // Detectar si es un dominio tipo "seguros.com.co"
  if (host.endsWith('hdiseguros.com.co')) {
    // Extraer el subdominio antes de "seguros.com.co"
    const subdomain = host.split('.')[0];
    // Extraer el país del subdominio (ejemplo: "domain-co" -> "co")
    return subdomain.split('-').pop();
  }

  // Para otros dominios, extraer el TLD
  return host.split('.').pop();
};

export const getCountryCode = () =>
  isLocalhost() ? constants.LANGUAGES.CL : getCountryCodeFromUrl();

export default {
  navigate,
  getCookie,
  getCountryCode,
};
