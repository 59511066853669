import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { LANGUAGES } from '@lmig-latam/adil-api-common-lib/constants';
import localizedStrings from '../../utils/localizedStrings';
import Image from '../Image/Image';
import { store } from '../../utils/configureStore';
import libertyLogo from '../../assets/gifs/liberty-logo.svg';
import HDILogoImage from '../../assets/images/logos/main-logo.svg';
import LoaderSplash from './LoaderSplash';
import { getCountryCode } from '../../utils/NavigationUtils';
import './styles.scss';

const AppLoader = ({ displayed, customTitle, customSubtitle }) => {
  const {
    settings: {
      environment: { language },
    },
  } = store.getState();

  const { MAIN_LOADER_TITLE, MAIN_LOADER_SUBTITLE } = localizedStrings(
    language,
  );

  const [counter, setCounter] = useState(0);

  const isMainCountry = useMemo(() => getCountryCode() === LANGUAGES.CO, []);

  const LibertyLogo = (
    <img
      className="mainLoader__load__libertyLogo"
      src={libertyLogo}
      alt="libertyLogo - load splasher"
    />
  );

  const HDILogo = (
    <Image src={HDILogoImage} className="mainLoader__load__libertyLogo" />
  );

  const getLogo = () => {
    let color = '#65A518'; // Default color HDI
    let logo = HDILogo; // HDI default logo

    if (!isMainCountry) {
      color = '#ffd000';
      logo = LibertyLogo;
    }

    return (
      <>
        <LoaderSplash color={color} />
        {logo}
      </>
    );
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setCounter(counter + 1);
    }, 1000);

    if (!displayed) {
      clearTimeout(timer);
      setCounter(0);
    }
  }, [counter, displayed]);

  return (
    displayed && (
      <div
        className="mainLoader"
        style={{
          ...(isMainCountry && { background: '#003960' }),
        }}
      >
        <div className="mainLoader__container__load">
          <div className="mainLoader__load">
            <div className="mainLoader__load__splash">{getLogo()}</div>
          </div>
        </div>
        <div className="mainLoader__container__text" id="textComponent">
          {counter > 7 && (
            <div>
              <p className="mainLoader__text">
                {customTitle || MAIN_LOADER_TITLE}
              </p>
              <p className="mainLoader__text">
                {customSubtitle || MAIN_LOADER_SUBTITLE}
              </p>
            </div>
          )}
        </div>
      </div>
    )
  );
};

AppLoader.propTypes = {
  displayed: PropTypes.bool.isRequired,
  customTitle: PropTypes.string,
  customSubtitle: PropTypes.string,
};

AppLoader.defaultProps = {
  customTitle: undefined,
  customSubtitle: undefined,
};

const mapStateToProps = ({
  settings: {
    appLoader: { displayed, customSubtitle, customTitle },
  },
}) => ({
  displayed,
  customSubtitle,
  customTitle,
});

export default connect(mapStateToProps, null)(AppLoader);
