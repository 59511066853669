import React from 'react';
import PropTypes from 'prop-types';

const LoaderSplash = ({ color }) => (
  <svg
    width="200px"
    height="200px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    className="lds-tail"
    style={{ background: 'none' }}
  >
    <defs>
      <filter
        id="tail-e6f9c25d73633"
        x="-100%"
        y="-100%"
        width="300%"
        height="300%"
        colorInterpolationFilters="sRGB"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="3" />
        <feComponentTransfer result="cutoff">
          <feFuncA type="linear" slope="60" intercept="-40" />
        </feComponentTransfer>
      </filter>
    </defs>

    <g filter="url(#tail-e6f9c25d73633)" transform="rotate(266.459 50 50)">
      <animateTransform
        attributeName="transform"
        type="rotate"
        calcMode="linear"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
        dur="7.5s"
        begin="0s"
        repeatCount="indefinite"
      />
      {[
        19,
        18,
        17,
        16,
        15,
        14,
        13,
        12,
        11,
        10,
        9,
        8,
        7,
        6,
        5,
        4,
        3,
        2,
        1,
        0,
      ].map((radius, index) => (
        <g
          key={`key-${index + 1}`}
          transform={`rotate(${359.819 - index * 0.02} 50.0066 50.0066)`}
        >
          <g transform="translate(50 16)">
            <circle
              cx="0"
              cy="0"
              r={radius}
              fill={color}
              transform="scale(0.30000000000000004)"
            />
          </g>
          <animateTransform
            attributeName="transform"
            calcMode="spline"
            type="rotate"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            dur="1.875"
            keySplines={`${0.13333333333333333 +
              index * 0.03333333333333333} 0 ${0.03333333333333333 +
              index * 0.03333333333333333} 1`}
            repeatCount="indefinite"
          />
        </g>
      ))}
    </g>
  </svg>
);

LoaderSplash.propTypes = {
  color: PropTypes.string.isRequired,
};

export default LoaderSplash;
