import constants from '@lmig-latam/adil-api-common-lib/constants/constants';
import devConfig from './dev';
import localhostConfig from './localhost';
import nonProdConfig from './nonProd';
import prodConfig from './prod';

const {
  LANGUAGES: { CL, VN, EC, CO, TH },
} = constants;

const doesArrayContainCurrentUrl = array =>
  array.some(
    string =>
      window.location.href.toLowerCase().indexOf(string.toLowerCase()) > -1,
  );

const isNonProduction = () => doesArrayContainCurrentUrl(['-nonprod']);

const isDevelopment = () => doesArrayContainCurrentUrl(['-dev']);

const isLocalhost = () =>
  doesArrayContainCurrentUrl([
    'localhost',
    '192.168',
    'libp45p-18218cf' /* Michael Wallace */,
    'libp45p-364076m' /* Mark McKim */,
    'libp45p-19079d5' /* Jason Priestley */,
  ]);

const isProduction = () =>
  !isNonProduction() && !isDevelopment() && !isLocalhost();

const getCountryCodeFromUrl = () => {
  const host = window.location.host.toLowerCase();
  return host.split('.').pop();
};

const getCountryCode = () =>
  isLocalhost() ? constants.LANGUAGES.CL : getCountryCodeFromUrl();

const getAppUrl = () => {
  const countryCode = getCountryCode();
  const countryURLAPI = {
    [EC]: 'https://adil-dev.libertyseguros.ec',
    [CO]: 'https://adil-dev.libertyseguros.co',
    [CL]: 'https://adil-dev.libertyseguros.cl',
    [VN]: 'https://adil-dev.libertyinsurance.com.vn',
    [TH]: 'https://adil-dev.lmginsurance.co.th',
  };
  return isLocalhost() ? countryURLAPI[countryCode] : window.location.origin;
};

const getEnvironmentConfig = () => {
  let environmentConfig = prodConfig;

  if (isNonProduction()) {
    environmentConfig = nonProdConfig;
  } else if (isDevelopment()) {
    environmentConfig = devConfig;
  } else if (isLocalhost()) {
    environmentConfig = localhostConfig;
  }

  return environmentConfig;
};

// Set appUrl for each of the configs
// This is to make them dynamic based on the window's current origin
// i.e. https://adil-nonprod.libertyseguros.co vs. https://adil-nonprod.libertyseguros.ec
[localhostConfig, devConfig, nonProdConfig, prodConfig].forEach(config => {
  // eslint-disable-next-line no-param-reassign
  config.appUrl = getAppUrl();
});

export {
  devConfig,
  prodConfig,
  nonProdConfig,
  localhostConfig,
  isLocalhost,
  isNonProduction,
  isDevelopment,
  isProduction,
  getEnvironmentConfig,
  doesArrayContainCurrentUrl,
};
